import { Link } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'

const NotFoundPage = () => (
    <Layout>
        <div id="notfound">
            <h1>Uh Oh!</h1>
            <p>
                Looks like something went wrong and we couldn't find the page
                you were looking for...the sadness{' '}
                <span aria-label="sad face emoji" role="img">
                    &#128546;
                </span>
                <Link to="/">
                    Maybe this is what you wanted{' '}
                    <span aria-label="winky face emoji" role="img">
                        &#128521;
                    </span>
                </Link>
            </p>
        </div>
    </Layout>
)

export default NotFoundPage
